import * as React from "react"
import logo from "../images/logo.png";
import approval from "../images/approval.png";
import code from "../images/code.png";
import github from "../images/github.svg";
import search from "../images/search.svg";

const Header = () => (
  <div className="relative w-full bg-white border-b-2 border-gray-100">
    <div className="px-4 mx-auto max-w-screen-xxl sm:px-6">
      <div className="flex items-center justify-between py-6 md:justify-start md:space-x-10">
        <div className="lg:w-0 lg:flex-1">
          <img src={logo} alt="releaselog logo" />
        </div>

        <nav className="hidden space-x-10 md:flex">
          <a href="#pricing"
            className="text-base font-semibold leading-6 text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:outline-none focus:text-gray-900">
            Pricing
          </a>
          <a href="#docs"
            className="text-base font-semibold leading-6 text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:outline-none focus:text-gray-900">
            Docs &amp; Guides
          </a>
        </nav>
        <div className="items-center justify-end hidden space-x-8 md:flex md:flex-1 lg:w-0">
          <a href="/auth/login"
            className="text-base font-semibold leading-6 text-gray-500 whitespace-no-wrap hover:text-gray-900 focus:outline-none focus:text-gray-900">
            Sign in
          </a>
          <span className="inline-flex rounded-md shadow-sm">
            <a href="/auth/signup"
              className="inline-flex items-center justify-center px-4 py-2 text-base font-semibold leading-6 text-white whitespace-no-wrap transition duration-150 ease-in-out bg-green-600 border border-transparent rounded-md hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-indigo active:bg-green-700">
              Sign up
            </a></span>
        </div>
      </div>
    </div>
  </div>
);

const EyeCatch = () => (
  <div className="w-full px-4 py-16 bg-purple-900 md:px-0">
    <div className="flex flex-col items-center justify-center w-full">
      <div className="flex flex-col items-center max-w-4xl md:px-8">
        <div className="max-w-xl mb-10 space-y-4 md:mx-auto sm:text-center lg:max-w-4xl md:mb-12">
          <h2
            className="max-w-4xl mb-20 font-sans text-3xl font-bold leading-5 tracking-tight text-white sm:text-5xl md:mx-auto"
          ><span className="relative inline-block">
              Changelog / release note management software built for developers 
            </span></h2>
          <div className="flex items-center content-center justify-start mt-4 space-x-4 md:justify-center">
            <a href="#signup">
              <button
                className="w-full px-6 py-3 text-base font-bold text-white bg-green-500 rounded">
                Try for free. No CC required.
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Footer = () => (
  <footer className="bg-white">
    <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:py-16 lg:px-8">
      <div className="pt-8 mt-12 border-t border-gray-200">
        <p className="text-base leading-6 text-gray-400 xl:text-center">
          © 2021 Releaselog team
        </p>
      </div>
    </div>
  </footer>
);

const plans = [
  {name: "Open Source", price: "free", features: ["GitHub Actions", "Unlimited Releases", "Google signin"]},
  {name: "Standard", price: "$30", features: ["All features from free plan", "Release approval", "Standard support"]},
  {name: "Enterprise", price: "custom pricing", features: ["All features from Standard", "Custom domain", "Analytics", "Expedited support"]}
]
const Pricing = () => (
  <>
    <div className="flex flex-col items-center mb-2">
      <h2 className="text-3xl text-primary-normal font-bold">Pricing</h2>
    </div>
    <section className="flex flex-col lg:flex-row items-start items-center lg:justify-center w-full w-full lg:px-10" >
      {plans.map(p => (
        <article
          className="bg-white w-4/5 lg:w-custom mb-10 lg:px-4 px-6 py-10 text-center text-primary-dark rounded-lg"
          key={p.price}
        >
          <h5 className="font-bold text-base">{p.name}</h5>
          <h2 className="pb-4 flex justify-center font-bold border-b border-gray-300">
            <span className="text-6xl">{p.price}</span>
          </h2>
          <ul className="text-sm font-bold">
            {p.features.map(f => (
              <li className="pt-4 pb-4 border-b border-gray-300" key={f}>{f}</li>
            ))}
          </ul>
          <a href="#signup">
            <button className="w-4/5 mr-6 ml-6 mt-12 px-6 py-3 text-base font-bold text-white bg-green-500 rounded">
              Sign up
            </button>
          </a>
        </article>
      ))}
    </section>
  </>
);

const features = [
  {img: github, title: "automated releaselog", description: "use our github action and API to setup automation"},
  {img: code, title: "monorepo changelog", description: "grasp list of releases from your monorepo"},
  {img: approval, title: "deploy approval", description: "release draft page to get deploy approval"},
  {img: search, title: "search by commit sha", description: "easily find out what release contains a specific change"},
]
const Features = () => (
  <div className="container max-w-6xl mx-auto">
    <div className="flex flex-col md:flex-row md:flex-wrap">
      {features.map(f => (
        <div className="w-full lg:w-1/4 md:w-1/2 text-center lg:text-left mt-4 p-2 flex flex-col justify-between" key={f.title}>
          <img src={f.img} className="w-10 self-start py-2 mx-auto lg:mx-0" alt={`${f.title} logo`} />
          <div>
            <h4 className="mb-1 font-bold">{f.title}</h4>
            <p className="mb-1">{f.description}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const IndexPage = () => {
  return (
    <main>
    <div id="container">
      <Header />
      <div className="w-full mx-auto">
        <EyeCatch />
        <div className="w-full px-4 py-16 mx-auto md:px-24 lg:px-8 lg:py-20">
          <div className="mb-10 md:mx-auto sm:text-center md:mb-12">
            <h2 className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              Automate your release log to keep everyone up to date
            </h2>
            <p className="text-base text-gray-600 md:text-lg">
              {/* potentially more text here */}
            </p>
          </div>
          <Features />
        </div>
      </div>
      </div>
      <Pricing />
      <Footer />
    </main>
  )
}

export default IndexPage
